export const ROUTES = {
  auth: {
    forgotPassowrd: "/forgot-password",
    updatePassword: "/update-password",
    login: "/login",
    main: "/",
  },
  dashboard: {
    companies: "/companies",
    AddCompanies: "/companies/add-companies",
    schools: "/school",
    AddSchools: "/school/add-school",
    fields: "/fields",
    addFields: "/fields/add-fields",
  },
};
