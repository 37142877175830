import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
} from "@coreui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { BsCameraFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCookie } from "react-use-cookie";
import { LABELS } from "src/components/labels/appLabels";
import { SUCCESS } from "src/components/labels/success";
import { schemaForCompanyAndSchool } from "src/components/schema";
import {
  addCompany,
  reset,
  updateCompany,
} from "src/features/dashboard/dashboardSlice";
import dummyProfile from "../../assets/images/avatars/dummy-profile.png";
import Loader from "../../components/loader";
import { getCompanyData as getCompanysData } from "../../features/dashboard/dashboardSlice";
import { ROUTES } from "src/components/routes/routes";
import { Helmet } from "react-helmet";
import { ERRORS } from "src/components/labels/error";

const AddCompany = () => {
  const userData = getCookie("userData");
  const navigation = useNavigate();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const hiddenProfileFileInput = useRef(null);
  const {
    companyAdd,
    getCompanyData,
    data,
    updateCompany: companyUpdate,
  } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const userDataString = userData ? JSON.parse(userData) : null;
  const { id } = useParams();
  const [editTrue, setEditTrue] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    webUrl: "",
    image: null,
  });

  useEffect(() => {
    if (!id) return;
    dispatch(getCompanysData(id));
  }, []);

  useEffect(() => {
    if (getCompanyData.isSuccess) {
      setEditTrue(true);
      if (data.getCompanyData && !data.getCompanyData.profileImage) {
        setCompanyId(data.getCompanyData._id);
        setInitialValues({
          name: data.getCompanyData.name,
          webUrl: data.getCompanyData.webSiteUrl,
          image: null,
        });
      } else if (data.getCompanyData.profileImage) {
        setCompanyId(data.getCompanyData._id);
        setInitialValues({
          name: data.getCompanyData.name,
          webUrl: data.getCompanyData.webSiteUrl,
          image: data.getCompanyData.profileImage,
        });
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [getCompanyData.isSuccess, dispatch]);

  useEffect(() => {
    if (companyAdd.isError) {
      toast.error(companyAdd.message);
      dispatch(reset());
    }
  }, [companyAdd.isError, companyAdd.message, dispatch]);

  useEffect(() => {
    if (companyAdd.isSuccess) {
      toast.success(SUCCESS.companyCreate);
      dispatch(reset());
      navigation(ROUTES.dashboard.companies);
    }
  }, [companyAdd.isSuccess, companyAdd.message, dispatch]);

  useEffect(() => {
    if (companyUpdate.isError) {
      toast.error(companyUpdate.message);
      dispatch(reset());
    }
  }, [companyUpdate.isError, companyUpdate.message, dispatch]);

  useEffect(() => {
    if (companyUpdate.isSuccess) {
      toast.success(SUCCESS.companyUpdated);
      dispatch(reset());
      navigation(ROUTES.dashboard.companies);
    }
  }, [companyUpdate.isSuccess, dispatch]);

  const handleSubmit = (values) => {
    if (editTrue) {
      let payload = {
        companyId: companyId,
      };
      let isUpdated = false;
      if (initialValues.name !== values.name) {
        payload.name = values.name.toLowerCase();
        isUpdated = true;
      }
      if (initialValues.webUrl !== values.webUrl) {
        payload.webSiteUrl = values.webUrl;
        isUpdated = true;
      }
      if (
        initialValues.image &&
        initialValues.image.startsWith('https') &&
        values.image !== initialValues.image ||  initialValues.image  !== values.image
      ) {
        payload.profileImage = values.image;
        isUpdated = true;
      }
      if (!isUpdated) {
        toast.error(ERRORS.notUpdateData);
        return; 
      }
      dispatch(updateCompany(payload));
    } else {
      const payload = {
        ...values,
        id: userDataString._id,
      };
      console.log('payload',payload)
      dispatch(addCompany(payload));
    }
  };

  const handleProfileClick = () => {
    if (hiddenProfileFileInput.current) {
      hiddenProfileFileInput.current.click();
    }
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file);
      setFieldValue("image", file);
    }
  };

  return (
    <>
     <Helmet>
        <title>{editTrue ? 'Edit Company' : 'Add Company'} | Flocks Admin</title>
      </Helmet>
      {getCompanyData.isLoading ? (
        <div className="loader-alignment">
          <Loader />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schemaForCompanyAndSchool}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form className="row g-3 needs-validation">
              <CCard className="p-3">
                <CContainer>
                  <CRow className="pb-3">
                    <CCol xs="auto" className="me-auto">
                      <h3>
                        {editTrue
                          ? LABELS.heading.editCompany
                          : LABELS.heading.addCompany}
                      </h3>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center pb-3">
                    <CCol>
                      <Field name="image">
                        {({ field }) => (
                          <>
                            <CFormLabel htmlFor="inputGroupFile02">
                              {LABELS.tableData.logo}
                            </CFormLabel>
                            <div className="img-div">
                              <input
                                type="file"
                                ref={hiddenProfileFileInput}
                                onChange={(e) =>
                                  handleFileChange(e, setFieldValue)
                                }
                                accept="image/png, image/jpeg"
                                hidden
                              />
                              <button
                                className="btn-img"
                                onClick={handleProfileClick}
                                type="button"
                              >
                                <BsCameraFill color="#000000" />
                              </button>
                            </div>
                            <img
                              src={
                                profilePhoto
                                ? URL.createObjectURL(profilePhoto)
                                : initialValues.image ? initialValues.image : dummyProfile
                              }
                              alt="User Profile"
                              width={100}
                              height={100}
                              className="img-company"
                            />
                            <ErrorMessage
                              name="image"
                              component="div"
                              className="text-danger"
                            />
                          </>
                        )}
                      </Field>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-start pb-3">
                    <CCol md={6}>
                      <Field name="name">
                        {({ field }) => (
                          <>
                            <CFormLabel htmlFor="nameInput">
                              {LABELS.tableData.name}
                            </CFormLabel>
                            <CFormInput
                              {...field}
                              type="text"
                              id="nameInput"
                              placeholder="Xord"
                              aria-describedby="exampleFormControlInputHelpInline"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </>
                        )}
                      </Field>
                    </CCol>
                    <CCol md={6}>
                      <Field name="webUrl">
                        {({ field }) => (
                          <>
                            <CFormLabel htmlFor="webUrlInput">
                              {LABELS.tableData.websiteUrl}
                            </CFormLabel>
                            <CFormInput
                              {...field}
                              type="text"
                              id="webUrlInput"
                              placeholder="https://example.com"
                              aria-describedby="exampleFormControlInputHelpInline"
                            />
                            <ErrorMessage
                              name="webUrl"
                              component="div"
                              className="text-danger"
                            />
                          </>
                        )}
                      </Field>
                    </CCol>
                  </CRow>
                  <CCol xs={12}>
                    <div className="d-flex justify-content-end align-items-end">
                      <CButton
                        disabled={
                          companyUpdate.isLoading || companyAdd.isLoading
                        }
                        color="primary"
                        type="submit"
                        className={
                          companyUpdate.isLoading || companyAdd.isLoading
                            ? "loadingBtn"
                            : ""
                        }
                      >
                        {companyUpdate.isLoading || companyAdd.isLoading ? (
                          <CSpinner color="base" className="loader-class" />
                        ) : editTrue ? (
                          LABELS.button.editCompany
                        ) : (
                          LABELS.button.addCompany
                        )}
                      </CButton>
                    </div>
                  </CCol>
                </CContainer>
              </CCard>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddCompany;
