export const ERRORS = {
  password:
    "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
  passwordCnfm:
    "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character",
  passwordReq: "Password is required",
  invalidEmail: "Invalid email",
  emailReq: "Email is required",
  otp: "OTP must be exactly 6 digits",
  cngmPassReq: "Confirm Password is required",
  passMatch: "Passwords must match",
  otpReq: "OTP is required",
  fieldReq: "Field name is required",
  nameReq: "Name is required",
  invalidUrl: "Please enter a valid URL. Example: https://www.example.com",
  urlReq: "Website URL is required",
  ownerNameReq: "Owner Name is required",
  logoReq: "Logo is required",
  fieldMin: "Field name is too short",
  fieldMax: "Field name is too long",
  nameShort: "Name is too short",
  nameLong: "Name is too long",
  somethingWrong: "Something went wrong!",
  notUpdateData: "Please update data first",
};
