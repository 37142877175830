import {
  CAvatar,
  CButton,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { ERRORS } from "src/components/labels/error";
import { SUCCESS } from "src/components/labels/success";
import Pagination from "src/components/Pagination";
import {
  deleteCompany,
  getAllCompanies,
  reset,
  resetState,
  verifyCompanies,
} from "src/features/dashboard/dashboardSlice";
import dummyProfile from "../../assets/images/avatars/dummy-profile.png";
import Loader from "../../components/loader";
import { VerticallyCentered } from "../notifications/modals/Modals";
import { LABELS } from "src/components/labels/appLabels";
import { ROUTES } from "src/components/routes/routes";
import { FaRegEdit } from "react-icons/fa";
import { Helmet } from 'react-helmet';

const CompaniesList = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { companyVerify, companyDelete, status, data } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(getAllCompanies({  page: page}));
  }, [page, dispatch]);

  useEffect(() => {
    if (companyVerify.isError) {
      toast.error(ERRORS.somethingWrong);
      dispatch(reset());
    }
  }, [companyVerify.isError, dispatch]);

  useEffect(() => {
    if (companyVerify.isSuccess) {
      toast.success(companyVerify.message);
      dispatch(getAllCompanies({ page: page }))
        .then((data) => {
          if (data.payload.status === 200) {
            dispatch(resetState());
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [companyVerify.isSuccess, companyVerify.message, dispatch, page]);

  useEffect(() => {
    if (companyDelete.isError) {
      toast.error(ERRORS.somethingWrong);
      dispatch(reset());
    }
  }, [companyDelete.isError, dispatch]);

  useEffect(() => {
    if (companyDelete.isSuccess) {
      toast.success(SUCCESS.deleteCompany);
      dispatch(getAllCompanies({ page: page }))
        .then((data) => {
          if (data.payload.status === 200) {
            dispatch(resetState());
            if (data.payload.data.data.allCompanies.length === 0 && page > 1) {
              setPage((prevPage) => prevPage - 1);
            } 
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [companyDelete.isSuccess, dispatch, page]);

  const verifyCompany = (value) => {
    const payload = {
      companyId: value,
    };
    dispatch(verifyCompanies(payload));
  };

  const deleteCompanyy = (value) => {
    const payload = {
      companyId: value,
    };
    dispatch(deleteCompany(payload));
  };

  const editCompany = (id) => {
    navigate(`/companies/add-companies/${id}`);
  };

  const handlePageChange = (pages) => {
    setPage(pages);
    dispatch(getAllCompanies({ page: pages }));
  };

  const handlePagination = (next) => {
    const nextPage = next ? page + 1 : page - 1;
    if (nextPage >= 1 && nextPage <= status.totalPages) {
      setPage(nextPage);
      dispatch(getAllCompanies({ page: nextPage }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Companies | Flocks Admin</title>
      </Helmet>
      <CContainer>
        <CRow className="pt-3 pb-3">
          <CCol xs="auto" className="me-auto">
            <h3>{LABELS.heading.companiesList}</h3>
          </CCol>
          <CCol xs="auto">
            <Link to={ROUTES.dashboard.AddCompanies}>
              <CButton>{LABELS.button.addCompanies}</CButton>
            </Link>
          </CCol>
        </CRow>
        <div className="table-container">
          <CTable striped hover className="fixed-width-table">
            <CTableHead className="table-header" color="primary">
              <CTableRow>
                <CTableHeaderCell scope="col">
                  {LABELS.tableData.logo}
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  {LABELS.tableData.name}
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  {LABELS.tableData.websiteUrl}
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  {LABELS.tableData.verified}
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  {LABELS.tableData.delete}
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  {LABELS.tableData.edit}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="loader-parent">
              {!status.isLoading ? (
                data.allCompanies?.length > 0 ? (
                  data.allCompanies.map((data, index) => (
                    <CTableRow key={index}>
                      <CTableDataCell>
                        <img src={data?.profileImage || dummyProfile} alt="logo-img" className="logo-img" />
                      </CTableDataCell>
                      <CTableDataCell className="truncate">
                        {data?.name ?? ""}
                      </CTableDataCell>
                      <CTableDataCell>{data?.webSiteUrl}</CTableDataCell>
                      <CTableDataCell>
                        {data?.verified ? (
                          <CButton
                            className="btn-custom"
                            color="primary"
                            disabled
                          >
                            {LABELS.button.verified}
                          </CButton>
                        ) : (
                          <VerticallyCentered
                            buttonName={LABELS.button.verify}
                            content={LABELS.messages.msgVerifyCompany}
                            loading={companyVerify.isLoading}
                            onClickFunction={() => {
                              verifyCompany(data._id);
                            }}
                          />
                        )}
                      </CTableDataCell>
                      <CTableDataCell>
                        {data?.verified ? (
                          <CButton className="btn-custom" color="danger" disabled>
                            {LABELS.button.delete}
                          </CButton>
                        ) : (
                          <VerticallyCentered
                            buttonName={LABELS.button.delete}
                            content={LABELS.messages.msgDeleteCompany}
                            loading={companyDelete.isLoading}
                            onClickFunction={() => deleteCompanyy(data._id)}
                          />
                        )}
                      </CTableDataCell>
                      <CTableDataCell>
                        {data?.verified ? (
                          <CButton className="btn-edit" color="none" disabled>
                            <FaRegEdit size={20} color="#163167" />
                          </CButton>
                        ) : (
                          <VerticallyCentered
                            buttonName={LABELS.button.edit}
                            content={LABELS.messages.msgEditCompany}
                            onClickFunction={() => editCompany(data._id)}
                          />
                        )}
                      </CTableDataCell>
                    </CTableRow>
                  ))
                ) : (
                  <CTableRow>
                    <CTableDataCell colSpan={8} className="text-center">
                      {LABELS.notFound.noCompanies}
                    </CTableDataCell>
                  </CTableRow>
                )
              ) : (
                <CTableRow>
                  <CTableDataCell colSpan={8} className="loader-container">
                    <div className="loader">
                      <Loader />
                    </div>
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </div>
      </CContainer>
      <CContainer>
        <div className="pagination-div">
          {status.isLoading ? (
            ""
          ) : status.totalPages > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={status.totalPages}
              onPageChange={handlePageChange}
              decrementPage={() => handlePagination(false)}
              incrementPage={() => handlePagination(true)}
            />
          ) : null}
        </div>
      </CContainer>
    </>
  );
};

export default CompaniesList;
