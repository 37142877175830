import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";

const initialState = {
  data: {
    allUsers: null,
    allCompanies: null,
    allSchools: null,
    bannedUser: null,
    allFields: null,
    getCompanyData: null,
    getSchoolData: null,
  },
  status: {
    totalPages: 0,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  bannedUser: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  companyDelete: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  companyVerify: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  schoolVerify: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  schoolDelete: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  companyAdd: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  schoolAdd: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fieldAdd: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  getCompanyData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  getSchoolData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  updateSchool: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  updateCompany: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
};

export const getAllUsers = createAsyncThunk(
  "admin/users",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.getAllUsers(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCompanies = createAsyncThunk(
  "admin/companyListings",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.getAllCompanies(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCompanyData = createAsyncThunk(
  "admin/getCompanyData",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.getCompanyData(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSchoolData = createAsyncThunk(
  "admin/getSchoolData",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.getSchoolData(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyCompanies = createAsyncThunk(
  "admin/toggleCompanyProfile",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.verifyCompanies(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "admin/deleteCompanyProfile",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.deleteCompany(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSchools = createAsyncThunk(
  "admin/schoolListings",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.getAllSchools(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifySchool = createAsyncThunk(
  "admin/toggleSchoolProfile",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.verifySchool(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteSchool = createAsyncThunk(
  "admin/toggleSchoolProfiles",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.deleteSchool(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const banndUser = createAsyncThunk(
  "admin/toggleBanUser",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.bannedUser(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addCompany = createAsyncThunk(
  "admin/createCompany",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.addCompany(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addSchool = createAsyncThunk(
  "admin/createSchool",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.addSchool(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSchool = createAsyncThunk(
  "admin/updateSchool",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.updateSchool(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "admin/updateCompany",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.updateCompany(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addField = createAsyncThunk(
  "admin/createField",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.addField(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFields = createAsyncThunk(
  "admin/allFields",
  async (payload, thunkAPI) => {
    try {
      return await dashboardService.getAllFields(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetState: (state) => {
      state.companyDelete = {
        ...state.companyDelete,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.companyVerify = {
        ...state.companyVerify,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.schoolVerify = {
        ...state.schoolVerify,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.schoolDelete = {
        ...state.schoolDelete,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.bannedUser = {
        ...state.bannedUser,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
    },
    reset: (state) => {
      state.data = {
        allUsers: null,
        allCompanies: null,
        allSchools: null,
        bannedUser: null,
        allFields: null,
        getCompanyData: null,
        getSchoolData: null,
      };
      state.status = {
        ...state.status,
        isError: false,
        isLoading: false,
        isSuccess: false,
        message: "",
      };
      state.companyAdd = {
        ...state.companyAdd,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.schoolAdd = {
        ...state.schoolAdd,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.fieldAdd = {
        ...state.fieldAdd,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.getCompanyData = {
        ...state.getCompanyData,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.getSchoolData = {
        ...state.getSchoolData,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.updateSchool = {
        ...state.updateSchool,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
      state.updateCompany = {
        ...state.updateCompany,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.status.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status.isLoading = false;
        state.status.isSuccess = true;
        state.data.allUsers = action.payload?.data?.data?.allUsers;
        state.status.totalPages = action.payload?.data?.data?.total_pages;
        state.status.message = action.payload?.data?.message;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status.isLoading = false;
        state.status.isError = true;
        state.status.message = action.payload;
      })
      .addCase(getAllCompanies.pending, (state) => {
        state.status.isLoading = true;
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        state.status.isLoading = false;
        state.status.isSuccess = true;
        state.data.allCompanies = action.payload?.data?.data.allCompanies;
        state.status.totalPages = action.payload?.data?.data.total_pages;
        state.status.message = action.payload?.data?.message;
      })
      .addCase(getAllCompanies.rejected, (state, action) => {
        state.status.isLoading = false;
        state.status.isError = true;
        state.status.message = action.payload;
      })
      .addCase(verifyCompanies.pending, (state) => {
        state.companyVerify.isLoading = true;
      })
      .addCase(verifyCompanies.fulfilled, (state, action) => {
        state.companyVerify.isLoading = false;
        state.companyVerify.isSuccess = true;
        state.companyVerify.message = action.payload?.data?.message;
      })
      .addCase(verifyCompanies.rejected, (state, action) => {
        state.companyVerify.isLoading = false;
        state.companyVerify.isError = true;
        state.companyVerify.message = action.payload;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.companyDelete.isLoading = true;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.companyDelete.isLoading = false;
        state.companyDelete.isSuccess = true;
        state.companyDelete.message = action.payload?.data?.message;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.companyDelete.isLoading = false;
        state.companyDelete.isError = true;
        state.companyDelete.message = action.payload;
      })
      .addCase(getAllSchools.pending, (state) => {
        state.status.isLoading = true;
      })
      .addCase(getAllSchools.fulfilled, (state, action) => {
        state.status.isLoading = false;
        state.status.isSuccess = true;
        state.data.allSchools = action.payload?.data?.data?.allSchools;
        state.status.totalPages = action.payload?.data?.data.total_pages;
        state.status.message = action.payload?.data?.message;
      })
      .addCase(getAllSchools.rejected, (state, action) => {
        state.status.isLoading = false;
        state.status.isError = true;
        state.status.message = action.payload;
      })
      .addCase(verifySchool.pending, (state) => {
        state.schoolVerify.isLoading = true;
      })
      .addCase(verifySchool.fulfilled, (state, action) => {
        state.schoolVerify.isLoading = false;
        state.schoolVerify.isSuccess = true;
        state.schoolVerify.message = action.payload?.data?.message;
      })
      .addCase(verifySchool.rejected, (state, action) => {
        state.schoolVerify.isLoading = false;
        state.schoolVerify.isError = true;
        state.schoolVerify.message = action.payload;
      })
      .addCase(deleteSchool.pending, (state) => {
        state.schoolDelete.isLoading = true;
      })
      .addCase(deleteSchool.fulfilled, (state, action) => {
        state.schoolDelete.isLoading = false;
        state.schoolDelete.isSuccess = true;
        state.schoolDelete.message = action.payload?.data?.message;
      })
      .addCase(deleteSchool.rejected, (state, action) => {
        state.schoolDelete.isLoading = false;
        state.schoolDelete.isError = true;
        state.schoolDelete.message = action.payload;
      })
      .addCase(banndUser.pending, (state) => {
        state.bannedUser.isLoading = true;
      })
      .addCase(banndUser.fulfilled, (state, action) => {
        state.bannedUser.isLoading = false;
        state.bannedUser.isSuccess = true;
        state.bannedUser.message = action.payload?.data?.message;
      })
      .addCase(banndUser.rejected, (state, action) => {
        state.bannedUser.isLoading = false;
        state.bannedUser.isError = true;
        state.bannedUser.message = action.payload;
      })
      .addCase(addCompany.pending, (state) => {
        state.companyAdd.isLoading = true;
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.companyAdd.isLoading = false;
        state.companyAdd.isSuccess = true;
        state.companyAdd.message = action.payload?.data?.message;
      })
      .addCase(addCompany.rejected, (state, action) => {
        state.companyAdd.isLoading = false;
        state.companyAdd.isError = true;
        state.companyAdd.message = action.payload;
      })
      .addCase(getCompanyData.pending, (state) => {
        state.getCompanyData.isLoading = true;
      })
      .addCase(getCompanyData.fulfilled, (state, action) => {
        state.getCompanyData.isLoading = false;
        state.getCompanyData.isSuccess = true;
        state.data.getCompanyData = action.payload?.data?.data.allCompanies[0];
        state.getCompanyData.message = action.payload?.data?.message;
      })
      .addCase(getCompanyData.rejected, (state, action) => {
        state.getCompanyData.isLoading = false;
        state.getCompanyData.isError = true;
        state.getCompanyData.message = action.payload;
      })
      .addCase(updateCompany.pending, (state) => {
        state.updateCompany.isLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.updateCompany.isLoading = false;
        state.updateCompany.isSuccess = true;
        state.updateCompany.message = action.payload?.data?.message;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.updateCompany.isLoading = false;
        state.updateCompany.isError = true;
        state.updateCompany.message = action.payload;
      })
      .addCase(getSchoolData.pending, (state) => {
        state.getSchoolData.isLoading = true;
      })
      .addCase(getSchoolData.fulfilled, (state, action) => {
        state.getSchoolData.isLoading = false;
        state.getSchoolData.isSuccess = true;
        state.data.getSchoolData = action.payload?.data?.data.allSchools[0];
        state.getSchoolData.message = action.payload?.data?.message;
      })
      .addCase(getSchoolData.rejected, (state, action) => {
        state.getSchoolData.isLoading = false;
        state.getSchoolData.isError = true;
        state.getSchoolData.message = action.payload;
      })
      .addCase(addSchool.pending, (state) => {
        state.schoolAdd.isLoading = true;
      })
      .addCase(addSchool.fulfilled, (state, action) => {
        state.schoolAdd.isLoading = false;
        state.schoolAdd.isSuccess = true;
        state.schoolAdd.message = action.payload?.data?.message;
      })
      .addCase(addSchool.rejected, (state, action) => {
        state.schoolAdd.isLoading = false;
        state.schoolAdd.isError = true;
        state.schoolAdd.message = action.payload;
      })
      .addCase(updateSchool.pending, (state) => {
        state.updateSchool.isLoading = true;
      })
      .addCase(updateSchool.fulfilled, (state, action) => {
        state.updateSchool.isLoading = false;
        state.updateSchool.isSuccess = true;
        state.updateSchool.message = action.payload?.data?.message;
      })
      .addCase(updateSchool.rejected, (state, action) => {
        state.updateSchool.isLoading = false;
        state.updateSchool.isError = true;
        state.updateSchool.message = action.payload;
      })
      .addCase(addField.pending, (state) => {
        state.fieldAdd.isLoading = true;
      })
      .addCase(addField.fulfilled, (state, action) => {
        state.fieldAdd.isLoading = false;
        state.fieldAdd.isSuccess = true;
        state.fieldAdd.message = action.payload?.data?.message;
      })
      .addCase(addField.rejected, (state, action) => {
        state.fieldAdd.isLoading = false;
        state.fieldAdd.isError = true;
        state.fieldAdd.message = action.payload;
      })
      .addCase(getAllFields.pending, (state) => {
        state.status.isLoading = true;
      })
      .addCase(getAllFields.fulfilled, (state, action) => {
        state.status.isLoading = false;
        state.status.isSuccess = true;
        state.status.isError = false;
        state.data.allFields = action.payload?.data?.data?.data;
        state.status.totalPages = action.payload?.data?.data.total_pages;
        state.status.message = action.payload?.data?.message;
      })
      .addCase(getAllFields.rejected, (state, action) => {
        state.status.isLoading = false;
        state.status.isError = true;
        state.status.isSuccess = false;
        state.status.message = action.payload;
      });
  },
});

export const { reset, resetState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
