import { cilLockLocked } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { schemaUpdatePassword } from "src/components/schema";
import {
  changePassword,
  forgotPassword,
  resendVerificationCode,
  reset,
} from "src/features/auth/authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Helmet } from "react-helmet";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [resendCode, setResendCode] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isError, message, dispatch]);

  useEffect(() => {
    if (isSuccess && message !== "Code sent successfully.") {
      toast.success(message);
      navigate({
        pathname: "/login",
      });
      dispatch(reset());
    }
    if (isSuccess && message === "Code sent successfully.") {
      toast.success(message);
    }
  }, [isSuccess, message, dispatch]);

  const initialValues = {
    password: "",
    confirmPassword: "",
    otp: "",
  };

  const handleSubmit = (values) => {
    const payload = {
      email: email,
      password: values.password,
      code: values.otp,
    };
    dispatch(changePassword(payload));
  };

  const onResend = () => {
    setResendCode(true);
    const payload = {
      email: email,
    };
    console.log("payload", payload);
    dispatch(forgotPassword(payload));
  };

  return (
    <>
      <Helmet>
        <title>Update Password | Flocks Admin</title>
      </Helmet>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center fixed-width">
        <CContainer className="pt-4">
          <CRow className="justify-content-center">
            <CCol
              lg={5}
              md={8}
              sm={10}
            >
              <CCard className="p-4">
                <CCardBody>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={schemaUpdatePassword}
                    onSubmit={handleSubmit}
                  >
                    {({ handleSubmit, values, setFieldValue }) => (
                      <CForm onSubmit={handleSubmit}>
                        <h2>Update Password</h2>
                        <p className="text-medium-emphasis">
                          Please Enter Your New Password
                        </p>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <Field name="password">
                            {({ field }) => (
                              <CFormInput
                                {...field}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <CInputGroupText
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </CInputGroupText>
                        </CInputGroup>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger error-message"
                        />

                        <CInputGroup className="mb-3 pt-2">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <Field name="confirmPassword">
                            {({ field }) => (
                              <CFormInput
                                {...field}
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <CInputGroupText
                            onClick={() =>
                              setConfirmShowPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                          </CInputGroupText>
                        </CInputGroup>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-danger error-message"
                        />
                        {/* OTP Input */}
                        <div className="pt-2">
                          <OTPInput
                            value={values.otp}
                            onChange={(value) => setFieldValue("otp", value)}
                            numInputs={6}
                            renderSeparator={
                              <span className="text-[#FFFFFF17]">-</span>
                            }
                            renderInput={(props) => <CFormInput {...props} />}
                            containerStyle="w-100 gap-1"
                            inputStyle="otpInput"
                          />
                          {/* End OTP Input */}
                          <ErrorMessage
                            name="otp"
                            component="div"
                            className="text-danger error-message-otp"
                          />
                        </div>
                        <div className="resend-btn-div">
                          <p className="code-text">
                            {" "}
                            Didn’t receive code yet ?
                          </p>
                          <CButton
                            onClick={onResend}
                            disabled={resendCode}
                            className="btn-resend"
                          >
                            Resend Otp
                          </CButton>
                        </div>
                        <div className="d-flex justify-content-center">
                          <CountdownCircleTimer
                            key={resendCode ? 1 : 0}
                            isPlaying={resendCode}
                            duration={60}
                            colors={[
                              "#163167",
                              "#163167",
                              "#163167",
                              "#163167",
                            ]}
                            strokeWidth={4}
                            onComplete={() => setResendCode(false)}
                            colorsTime={[7, 5, 2, 0]}
                            size={100}
                          >
                            {({ remainingTime }) => {
                              const minutes = Math.floor(remainingTime / 60);
                              const seconds = remainingTime % 60;
                              return (
                                <p className="mt-2">{`${
                                  minutes < 10 ? `0${minutes}` : minutes
                                }:${
                                  seconds < 10 ? `0${seconds}` : seconds
                                }`}</p>
                              );
                            }}
                          </CountdownCircleTimer>
                        </div>
                        <CButton
                          color="primary"
                          className="w-100 mt-4"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <CSpinner
                              color="base"
                              className="loader-class"
                            />
                          ) : (
                            "Continue"
                          )}
                        </CButton>
                      </CForm>
                    )}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  );
};

export default UpdatePassword;
