import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LABELS } from "src/components/labels/appLabels";
import Pagination from "src/components/Pagination";
import { ROUTES } from "src/components/routes/routes";
import { getAllFields } from "src/features/dashboard/dashboardSlice";
import Loader from "../../components/loader";
import { Helmet } from 'react-helmet';


const FieldList = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { status, data } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getAllFields({ page: page }));
  }, []);

  const handlePageChange = (pages) => {
    setPage(pages);
    const payload = {
      page: pages,
    };
    dispatch(getAllFields(payload));
  };

  const handlePagination = (next) => {
    const nextPage = next ? page + 1 : page - 1;
    if (nextPage >= 1 && nextPage <= status.totalPages) {
      setPage(nextPage);
      const payload = {
        page: nextPage,
      };
      dispatch(getAllFields(payload));
    }
  };

  return (
    <>
     <Helmet>
        <title>Fields | Flocks Admin</title>
      </Helmet>
      <CContainer>
        <CRow className="pt-3 pb-3">
          <CCol xs="auto" className="me-auto">
            <h3>{LABELS.heading.fieldList}</h3>
          </CCol>
          <CCol xs="auto">
            <Link to={ROUTES.dashboard.addFields}>
              <CButton>{LABELS.button.addFields}</CButton>
            </Link>
          </CCol>
        </CRow>
        <div className="table-container">
       <CTable striped hover className="fixed-width-table">
       <CTableHead className="table-header" color="primary">
            <CTableRow>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.fieldName}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="loader-parent">
            {!status.isLoading ? (
              data.allFields?.length > 0 ? (
                data.allFields.map((data, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell className="truncate">
                      {data?.fieldName ?? ""}
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow>
                  <CTableDataCell colSpan={8} className="text-center">
                    {LABELS.notFound.noFields}
                  </CTableDataCell>
                </CTableRow>
              )
            ) : (
              <CTableRow>
                <CTableDataCell colSpan={8} className="loader-container">
                  <div className="loader">
                    <Loader />
                  </div>
                </CTableDataCell>
              </CTableRow>
            )}
          </CTableBody>
        </CTable>
       </div>
      </CContainer>
      <CContainer>
        <div className="pagination-div ">
          {status.isLoading ? (
            ""
          ) : status.totalPages > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={status.totalPages}
              onPageChange={handlePageChange}
              decrementPage={() => handlePagination(false)}
              incrementPage={() => handlePagination(true)}
            />
          ) : null}
        </div>
      </CContainer>
    </>
  );
};

export default FieldList;
