export const LABELS = {
  heading: {
    userList: "Users List",
    companiesList: "Companies List",
    addCompany: "Add Company",
    editCompany: "Edit Company",
    schoolList: "Schools List",
    addSchool: "Add School",
    editSchool: "Edit School",
    fieldList: "Fields List",
    addFields: "Add Fields",
  },
  tableData: {
    profile: "Profile",
    name: "Name",
    email: "Email",
    banned: "Banned",
    logo: "Logo",
    websiteUrl: "Website URL",
    verified: "Verified",
    delete: "Delete",
    edit: "Edit",
    fieldName: "Field Name",
  },
  button: {
    bannedUser: "Ban",
    unBannedUser: "Unban",
    addCompanies: "Add Companies",
    verify: "Verify",
    verified: "Verified",
    delete: "Delete",
    edit: "Edit",
    addSchool: "Add School",
    editSchool: "Update",
    addCompany: "Add Company",
    editCompany: "Update",
    addFields: "Add Fields",
  },
  messages: {
    msgUnBannedUser: "Are you sure you'd like to unbanned this user?",
    msgBannedUser: "Are you sure you'd like to ban this user?",
    msgVerifyCompany: "Are you sure you want to verify company",
    msgDeleteCompany: "Are you sure you want to delete company",
    msgEditCompany: "Are you sure you want to edit company",
    msgVerifySchool: "Are you sure you want to verify school",
    msgDeleteSchool: "Are you sure you want to delete school",
    msgEditSchool: "Are you sure you want to edit school",
  },
  notFound: {
    noUsers: " No users found.",
    noCompanies: "No companies found.",
    noSchool: "No schools found.",
    noFields: "No fields found.",
  },
};
