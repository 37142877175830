import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CFormInput,
  CRow,
  CSpinner,
} from "@coreui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "react-use-cookie";
import { LABELS } from "src/components/labels/appLabels";
import { SUCCESS } from "src/components/labels/success";
import { ROUTES } from "src/components/routes/routes";
import { schemaAddFields } from "src/components/schema";
import { addField, reset } from "src/features/dashboard/dashboardSlice";
import { Helmet } from 'react-helmet';

const AddFields = () => {
  const dispatch = useDispatch();
  const userData = getCookie("userData");
  const userDataString = userData ? JSON.parse(userData) : null;
  const { fieldAdd } = useSelector((state) => state.dashboard);
  const navigation = useNavigate();

  useEffect(() => {
    if (fieldAdd.isError) {
      toast.error(fieldAdd.message);
      dispatch(reset());
    }
  }, [fieldAdd.isError, fieldAdd.message, dispatch]);

  useEffect(() => {
    if (fieldAdd.isSuccess) {
      toast.success(SUCCESS.fieldCreate);
      dispatch(reset());
      navigation(ROUTES.dashboard.fields);
    }
  }, [fieldAdd.isSuccess, dispatch]);

  const initialValues = {
    fieldName: "",
  };

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      userId: userDataString._id,
    };
    dispatch(addField(payload));
  };

  return (
    <>
 <Helmet>
        <title>Add Fields | Flocks Admin</title>
      </Helmet>
    <Formik
      initialValues={initialValues}
      validationSchema={schemaAddFields}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form className="row g-3 needs-validation">
          <CCard className="p-3">
            <CContainer>
              <CRow className="pb-3">
                <CCol xs="auto" className="me-auto">
                  <h3>{LABELS.heading.addFields}</h3>
                </CCol>
              </CRow>

              <CRow className="align-items-start pb-3">
                <CCol>
                  <Field name="fieldName">
                    {({ field }) => (
                      <>
                        <CFormInput
                          {...field}
                          type="text"
                          label="Name"
                          placeholder="software engineer"
                          aria-describedby="exampleFormControlInputHelpInline"
                        />
                        {fieldAdd.isError ? (
                          <p className="text-danger">{fieldAdd.message}</p>
                        ) : (
                          <ErrorMessage
                            name="fieldName"
                            component="div"
                            className="text-danger"
                          />
                        )}
                      </>
                    )}
                  </Field>
                </CCol>
              </CRow>
            <CCol xs={12}>
              <div className="d-flex justify-content-end align-items-end">
                <CButton
                  color="primary"
                  type="submit"
                  disabled={fieldAdd.isLoading}
                  className={
                    fieldAdd.isLoading
                      ? "loadingBtn"
                      : ""
                  }
                >
                  {fieldAdd.isLoading ? <CSpinner color="base" className="loader-class" /> : LABELS.button.addFields}
                </CButton>
              </div>
            </CCol>
            </CContainer>
          </CCard>
        </Form>
      )}
    </Formik>
    </>
  );
};

export default AddFields;
