import { useDispatch } from "react-redux";
import { logout } from "src/features/auth/authSlice";

export const Logout = () => {
  const dispatch = useDispatch();
  setTimeout(() => {
    dispatch(reset());
    dispatch(logout());
    setVisible(false);
    window.location.href = "/";
  }, 1000);
};
