import axios from "axios";
import jwtDecode from "jwt-decode";
import { getCookie, setCookie } from "react-use-cookie";
import { Logout } from "./helper";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const noAuthEndpoints = [
  "/auth/signIn",
  "/auth/forgotPassword",
  "/auth/confirmForgotPassword",
  "/auth/refreshAccessToken",
  "/auth/resendVerificationCode",
];

axiosInstance.interceptors.request.use(
  async (config) => {
    const url = new URL(config.url, config.baseURL);
    const path = url.pathname;

    if (!noAuthEndpoints.includes(path)) {
      let tokens = getCookie("authTokens");
      const stringTokens = tokens ? JSON.parse(tokens) : null;
      let { AccessToken } = stringTokens || {};

      if (AccessToken) {
        const decodedToken = jwtDecode(AccessToken);
        const expiryTimestamp = new Date(decodedToken.exp * 1000);
        if (expiryTimestamp < new Date()) {
          try {
            const response = await axios.post(
              `${BASE_URL}/auth/refreshAccessToken`,
              {
                refreshToken: stringTokens?.RefreshToken,
              }
            );
            if (response?.status === 200) {
              const newToken = response?.data?.data.AccessToken;
              const newTokens = {
                ...stringTokens,
                AccessToken: newToken,
              };
              setCookie("authTokens", JSON.stringify(newTokens), {
                maxAge: 365 * 24 * 60 * 60,
              });
              localStorage.setItem("authTokens", JSON.stringify(newTokens));
              AccessToken = newToken;
            }
          } catch (error) {
            console.debug("ERROR[UNABLE_TO_GET_TOKEN]", error);
            if (error.response?.status === 401) {
              Logout();
              return [false, error.response.data.message];
            }
            throw error;
          }
        }
      }

      if (AccessToken) {
        config.headers.Authorization = `Bearer ${AccessToken}`;
      } else {
        Logout();
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
