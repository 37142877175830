import { cibGmail } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { schemaForgotEmail } from "src/components/schema";
import { forgotPassword, reset } from "src/features/auth/authSlice";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isError, message, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      navigate({
        pathname: "/update-password",
        search: createSearchParams({
          email: email,
        }).toString(),
      });
      dispatch(reset());
    }
  }, [isSuccess, message, dispatch]);

  const handleSubmit = (values) => {
    dispatch(forgotPassword(values));
    setEmail(values.email);
  };

  return (
    <>
     <Helmet>
        <title>Forgot Password | Flocks Admin</title>
      </Helmet>
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center fixed-width">
      <CContainer className="pt-4 ">
        <CRow className="justify-content-center">
          <CCol lg={5} md={8} sm={10}>
            <CCard className="p-4">
              <CCardBody>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={schemaForgotEmail}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit }) => (
                    <CForm onSubmit={handleSubmit}>
                      <h1>Forgot Password</h1>
                      <p className="text-medium-emphasis">
                        Please Enter Your Email
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cibGmail} />
                        </CInputGroupText>
                        <Field name="email">
                          {({ field }) => (
                            <CFormInput
                              {...field}
                              type="email"
                              placeholder="Email"
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </CInputGroup>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger error-message mb-3"
                      />
                      <CButton
                        color="primary"
                        className='w-100 mt-3'
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? <CSpinner color="base" className="loader-class" /> : "Continue"}
                      </CButton>
                    </CForm>
                  )}
                </Formik>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    </>
  );
};

export default ForgotPassword;
