export const ENDPOINT = {
  auth: {
    login: "/auth/signIn",
    forgotPassword: "/auth/forgotPassword",
    confirmForgotPassword: "/auth/confirmForgotPassword",
    resendVerificationCode: "/auth/resendVerificationCode",
  },
  users: {
    getAllUsers: "/admin/users",
    bannedUser: "/admin/toggleBanUser",
  },
  company: {
    getAllCompanies: "/admin/companyListings",
    verifyCompany: "/admin/toggleCompanyProfile",
    deleteCompany: "/admin/deleteCompanyProfile",
    addCompany: "/admin/createCompany",
    updateCompany: "/admin/updateCompanyProfile",
    companyProfile: "/admin/companyProfile",
  },
  school: {
    getAllSchools: "/admin/schoolListings",
    verifySchool: "/admin/toggleSchoolProfile",
    deleteSchool: "/admin/deleteSchoolProfile",
    addSchool: "/admin/createSchool",
    updateSchool: "/admin/updateSchoolProfile",
    schoolProfile: "/admin/schoolProfile",
  },
  field: {
    addField: "/admin/createField",
    getAllFields: "/admin/allFields",
  },
};
