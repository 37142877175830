import axios from "src/utils/axiosInstance";
import { removeCookie, setCookie } from "react-use-cookie";
import { ENDPOINT } from "src/components/endpoint/endpoint";

const login = async (payload) => {
  const response = await axios.post(ENDPOINT.auth.login, payload);
  if (response) {
    localStorage.setItem(
      "authTokens",
      JSON.stringify(response.data.data.tokens)
    );
    localStorage.setItem(
      "userData",
      JSON.stringify(response.data.data.userData)
    );
    setCookie("authTokens", JSON.stringify(response.data.data.tokens));
    setCookie("userData", JSON.stringify(response.data.data.userData));
  }
  return response;
};

const forgotPassword = async (payload) => {
  const response = await axios.post(ENDPOINT.auth.forgotPassword, payload);
  return response;
};

const changePassword = async (payload) => {
  const response = await axios.post(
    ENDPOINT.auth.confirmForgotPassword,
    payload
  );
  return response;
};

const resendCode = async (payload) => {
  const response = await axios.post(
    ENDPOINT.auth.resendVerificationCode,
    payload
  );
  return response;
};

const logout = async () => {
  removeCookie("userData");
  removeCookie("authTokens");
  localStorage.removeItem("userData");
  localStorage.removeItem("authTokens");
};

const authService = {
  login,
  logout,
  forgotPassword,
  changePassword,
  resendCode,
};

export default authService;
