import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import sidebarReducer from "./features/sidebar/sidebarSlice";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    sidebar: sidebarReducer,
  },
  middleware: customizedMiddleware,
});

export default store;
