import { cilLockLocked } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import Loader from "../../../components/loader";

export const VerticallyCentered = ({
  buttonName,
  title,
  content,
  onClickFunction,
  loading,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <CButton
        color={
          buttonName === "Delete"
            ? "danger"
            : buttonName === "Logout"
            ? null
            : buttonName === "Edit"
            ? ""
            : "primary"
        }
        onClick={() => setVisible(!visible)}
        className={`${
          buttonName === "Edit"
            ? ""
            : buttonName === "Logout"
            ? ""
            : "btn-custom"
        }`}
      >
        {buttonName === "Logout" ? (
          <>
            <CIcon icon={cilLockLocked} className="me-2 mt-2" /> {buttonName}
          </>
        ) : buttonName === "Edit" ? (
          <>
            <div className="edit-btn-div">
              <FaRegEdit size={20} color="#163167" />
            </div>
          </>
        ) : (
          buttonName
        )}
      </CButton>
      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <CModalHeader>
          {title ? <CModalTitle>{title}</CModalTitle> : ""}
        </CModalHeader>
        <CModalBody>{content}</CModalBody>
        {loading ? (
          <div className="mb-3">
            <Loader />
          </div>
        ) : (
          <CModalFooter>
            <CButton
              className="btn-custom"
              color="danger"
              onClick={() => setVisible(false)}
            >
              Cancel
            </CButton>
            <CButton
              color="primary"
              onClick={onClickFunction}
              className="btn-custom"
            >
              Yes
            </CButton>
          </CModalFooter>
        )}
      </CModal>
    </>
  );
};
