import {
  CAvatar,
  CButton,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { LABELS } from "src/components/labels/appLabels";
import { ERRORS } from "src/components/labels/error";
import { SUCCESS } from "src/components/labels/success";
import Pagination from "src/components/Pagination";
import { ROUTES } from "src/components/routes/routes";
import {
  deleteSchool,
  getAllSchools,
  reset,
  resetState,
  verifySchool,
} from "src/features/dashboard/dashboardSlice";
import dummyProfile from "../../assets/images/avatars/dummy-profile.png";
import Loader from "../../components/loader";
import { VerticallyCentered } from "../notifications/modals/Modals";
import { Helmet } from 'react-helmet';


const SchoolList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { status, data, schoolVerify, schoolDelete } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    if (schoolVerify.isSuccess) {
      toast.success(schoolVerify.message);
      const res = dispatch(getAllSchools({ page: page })).then((data)=> {
if(data.payload.status === 200) {
  dispatch(resetState());
}
      }).catch((error)=> {
        console.log('error',error)

      });
      console.log('res',res)
    }
  }, [schoolVerify.isSuccess, schoolVerify.message, dispatch]);

  useEffect(() => {
    if (schoolVerify.isError) {
      toast.error(ERRORS.somethingWrong);
      dispatch(reset());
    }
  }, [schoolVerify.isError, dispatch]);

  useEffect(() => {
    if (schoolDelete.isSuccess) {
      toast.success(SUCCESS.deleteSchool);
const res = dispatch(getAllSchools({ page: page })) .then((data) => {
  if (data.payload.status === 200) {
    dispatch(resetState());
    if (data.payload.data.data.allSchools.length === 0 && page > 1) {
      setPage((prevPage) => prevPage - 1);
    } 
  }
})
.catch((error) => {
  console.log('error', error);
});
    }
  }, [schoolDelete.isSuccess, dispatch]);

  useEffect(() => {
    if (schoolDelete.isError) {
      toast.error(ERRORS.somethingWrong);
      dispatch(reset());
    }
  }, [schoolDelete.isError, dispatch]);

  useEffect(() => {
    dispatch(getAllSchools({ page: page }));
  }, []);

  const verifySchools = (value) => {
    const payload = {
      schoolId: value,
    };
    dispatch(verifySchool(payload));
  };

  const deleteSchools = (value) => {
    const payload = {
      schoolId: value,
    };
    dispatch(deleteSchool(payload));
  };

  const editSchool = (id) => {
    navigate(`/school/add-school/${id}`);
  };

  const handlePageChange = (pages) => {
    setPage(pages);
    const payload = {
      page: pages,
    };
    dispatch(getAllSchools(payload));
  };

  const handlePagination = (next) => {
    const nextPage = next ? page + 1 : page - 1;
    if (nextPage >= 1 && nextPage <= status.totalPages) {
      setPage(nextPage);
      const payload = {
        page: nextPage,
      };
      dispatch(getAllSchools(payload));
    }
  };

  return (
    <>
       <Helmet>
        <title>Schools | Flocks Admin</title>
      </Helmet>
      <CContainer>
        <CRow className="pt-3 pb-3">
          <CCol xs="auto" className="me-auto">
            <h3>{LABELS.heading.schoolList}</h3>
          </CCol>
          <CCol xs="auto">
            <Link to={ROUTES.dashboard.AddSchools}>
              <CButton>{LABELS.button.addSchool}</CButton>
            </Link>
          </CCol>
        </CRow>
        <div className="table-container">
        <CTable striped hover>
        <CTableHead className="table-header" color="primary">
            <CTableRow>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.logo}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.name}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.websiteUrl}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.verified}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.delete}
              </CTableHeaderCell>
              <CTableHeaderCell scope="col">
                {LABELS.tableData.edit}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="loader-parent">
            {!status.isLoading ? (
              data.allSchools?.length > 0 ? (
                data.allSchools.map((data, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>
                      
<img src={data?.profileImage || dummyProfile} alt="logo-img" className="logo-img" />
                     
                    </CTableDataCell>
                    <CTableDataCell className="truncate">
                      {data?.name ?? ""}
                    </CTableDataCell>
                    <CTableDataCell>{data?.webSiteUrl}</CTableDataCell>
                    <CTableDataCell>
                      {data?.verified ? (
                        <CButton
                          className="btn-custom"
                          color="primary"
                          disabled
                        >
                          {LABELS.button.verified}
                        </CButton>
                      ) : (
                        <VerticallyCentered
                          buttonName={LABELS.button.verify}
                          content={LABELS.messages.msgVerifySchool}
                          loading={schoolVerify.isLoading}
                          onClickFunction={() => {
                            verifySchools(data._id);
                          }}
                        />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      {data?.verified ? (
                        <CButton className="btn-custom" color="danger" disabled>
                          {LABELS.button.delete}
                        </CButton>
                      ) : (
                        <VerticallyCentered
                          buttonName={LABELS.button.delete}
                          content={LABELS.messages.msgDeleteSchool}
                          loading={schoolDelete.isLoading}
                          onClickFunction={() => deleteSchools(data._id)}
                        />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      {data?.verified ? (
                        <CButton className="btn-edit" color="none" disabled>
                          <FaRegEdit size={20} color="#163167" />
                        </CButton>
                      ) : (
                        <VerticallyCentered
                          buttonName={LABELS.button.edit}
                          content={LABELS.messages.msgEditSchool}
                          onClickFunction={() => editSchool(data._id)}
                        />
                      )}
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow>
                  <CTableDataCell colSpan={8} className="text-center">
                    {LABELS.notFound.noSchool}
                  </CTableDataCell>
                </CTableRow>
              )
            ) : (
              <CTableRow>
                <CTableDataCell colSpan={8} className="loader-container">
                  <div className="loader">
                    <Loader />
                  </div>
                </CTableDataCell>
              </CTableRow>
            )}
          </CTableBody>
        </CTable>
        </div>
      </CContainer>
      <CContainer>
        <div className="pagination-div">
          {status.isLoading ? (
            ""
          ) : status.totalPages > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={status.totalPages}
              onPageChange={handlePageChange}
              decrementPage={() => handlePagination(false)}
              incrementPage={() => handlePagination(true)}
            />
          ) : null}
        </div>
      </CContainer>
    </>
  );
};

export default SchoolList;
