import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { getCookie } from "react-use-cookie";
import "./scss/style.scss";
import AddCompany from "./views/companies/AddCompany";
import CompaniesList from "./views/companies/CompaniesList";
import AddFields from "./views/fields/AddFields";
import FieldList from "./views/fields/FieldList";
import ForgotPassword from "./views/pages/forgotpassword/Forgotpassword";
import UpdatePassword from "./views/pages/updatepassword/UpdatePassword";
import AddSchool from "./views/school/AddSchool";
import SchoolList from "./views/school/SchoolList";
import Dashboard from "./views/dashboard/Dashboard";
import Loader from "./components/loader";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const Navigation = () => {
  const { user } = useSelector((state) => state.auth);
  const userData = getCookie("userData");
  const userDataString = userData ? JSON.parse(userData) : null;
  const isAuthenticated = !!userDataString;

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Routes>
          <Route
            path="/login"
            element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/forgot-password"
            element={
              !isAuthenticated ? <ForgotPassword /> : <Navigate to="/" />
            }
          />
          <Route
            path="/update-password"
            element={
              !isAuthenticated ? <UpdatePassword /> : <Navigate to="/" />
            }
          />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route
            path="/"
            element={
              isAuthenticated ? <DefaultLayout /> : <Navigate to="/login" />
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="companies" element={<CompaniesList />} />
            <Route path="companies/add-companies" element={<AddCompany />} />
            <Route
              path="companies/add-companies/:id"
              element={<AddCompany />}
            />
            <Route path="school" element={<SchoolList />} />
            <Route path="school/add-school" element={<AddSchool />} />
            <Route path="school/add-school/:id" element={<AddSchool />} />
            <Route path="fields" element={<FieldList />} />
            <Route path="fields/add-fields" element={<AddFields />} />
          </Route>
          <Route
            path="*"
            element={<Navigate to={isAuthenticated ? "/" : "/login"} />}
          />
        </Routes>
      </React.Suspense>
    </Router>
  );
};

export default Navigation;
