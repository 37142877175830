import {
  CAvatar,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "src/components/Pagination";
import { banndUser, getAllUsers, reset, resetState } from "src/features/dashboard/dashboardSlice";
import dummyProfile from "../../assets/images/avatars/dummy-profile.png";
import Loader from "../../components/loader";
import { VerticallyCentered } from "../notifications/modals/Modals";
import { LABELS } from "src/components/labels/appLabels";
import CustomSelect from "../customSelect/CustomSelect";
import { Helmet } from 'react-helmet';

const options = [
  { value: "all", label: "All" },
  { value: "true", label: "Ban" },
  { value: "false", label: "Unban" },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [bannedVal, setBanned] = useState("all");
  const { bannedUser, data, status,  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (bannedUser.isSuccess) {
      toast.success(bannedUser.message);
      dispatch(getAllUsers({ page, isBanned: bannedVal })).then((data) => {
        if (data.payload.status === 200) {
          setPage(data.payload.data.data.page)
          dispatch(resetState());
        }
      })
      .catch((error) => console.log('error', error));;
    }
  }, [bannedUser.isSuccess, bannedUser.message, dispatch]);

  useEffect(() => {
    if (bannedUser.isError) {
      toast.error(bannedUser.message);
      dispatch(reset());
    }
  }, [bannedUser.isError, bannedUser.message, dispatch]);

  useEffect(() => {
    dispatch(getAllUsers({ page, isBanned: bannedVal }));
  }, [dispatch, page, bannedVal]);

  const bannedUsers = (data) => {
    dispatch(banndUser({ userId: data.userId }));
  };

  const handlePageChange = (pages) => {
    setPage(pages);
    dispatch(getAllUsers({ page: pages, isBanned: bannedVal }));
  };

  const handlePagination = (next) => {
    const nextPage = next ? page + 1 : page - 1;
    if (nextPage >= 1 && nextPage <= status.totalPages) {
      setPage(nextPage);
      dispatch(getAllUsers({ page: nextPage, isBanned: bannedVal }));
    }
  };

  const handleSelectChange = (option) => {
    setPage(1);
    setBanned(option.value);
    dispatch(getAllUsers({ page: 1, isBanned: option.value }));
  };

  return (
    <>
     <Helmet>
        <title>Users | Flocks Admin</title>
      </Helmet>
      <CContainer>
        <CRow className="pt-3 pb-3">
          <CCol xs="auto" className="me-auto">
            <h3>{LABELS.heading.userList}</h3>
          </CCol>
        </CRow>
        <div className="select-div">
          <CustomSelect options={options} onChange={handleSelectChange} />
        </div>
        <div className="table-container">
          <CTable striped hover>
            <CTableHead className="table-header" color="primary">
              <CTableRow>
                <CTableHeaderCell scope="col">{LABELS.tableData.profile}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{LABELS.tableData.name}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{LABELS.tableData.email}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{LABELS.tableData.banned}</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {!status.isLoading ? (
                data.allUsers?.length > 0 ? (
                  data.allUsers.map((data, index) => (
                    <CTableRow key={index}>
                      <CTableDataCell>
                      <img src={data?.profileImage || dummyProfile} alt="logo-img" className="logo-img" />

                      </CTableDataCell>
                      <CTableDataCell>{data?.firstName ?? ""}</CTableDataCell>
                      <CTableDataCell>{data?.email}</CTableDataCell>
                      <CTableDataCell>
                        {data?.isBanned ? (
                          <VerticallyCentered
                            buttonName={LABELS.button.unBannedUser}
                            content={LABELS.messages.msgUnBannedUser}
                            loading={bannedUser.isLoading}
                            onClickFunction={() => bannedUsers({ userId: data?._id })}
                          />
                        ) : (
                          <VerticallyCentered
                            buttonName={LABELS.button.bannedUser}
                            loading={bannedUser.isLoading}
                            content={LABELS.messages.msgBannedUser}
                            onClickFunction={() => bannedUsers({ userId: data?._id })}
                          />
                        )}
                      </CTableDataCell>
                    </CTableRow>
                  ))
                ) : (
                  <CTableRow>
                    <CTableDataCell colSpan={4} className="text-center">
                      {LABELS.notFound.noUsers}
                    </CTableDataCell>
                  </CTableRow>
                )
              ) : (
                <CTableRow>
                  <CTableDataCell colSpan={4} className="loader-container">
                    <div className="loader">
                      <Loader />
                    </div>
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </div>
      </CContainer>
      <CContainer>
        <div className="pagination-div">
          {status.isLoading ? (
            ""
          ) : status.totalPages > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={status.totalPages}
              onPageChange={handlePageChange}
              decrementPage={() => handlePagination(false)}
              incrementPage={() => handlePagination(true)}
            />
          ) : null}
        </div>
      </CContainer>
    </>
  );
};

export default Dashboard;