import * as Yup from "yup";
import { REGEX } from "../regex";
import { ERRORS } from "../labels/error";

export const schemaLogin = Yup.object({
  email: Yup.string().email(ERRORS.invalidEmail).required(ERRORS.emailReq),
  password: Yup.string()
    .required(ERRORS.passwordReq)
    .matches(REGEX.password, ERRORS.password),
});

export const schemaForCompanyAndSchool = Yup.object({
  name: Yup.string()
    .min(3, ERRORS.nameShort)
    .max(100, ERRORS.nameLong)
    .required(ERRORS.nameReq),
  webUrl: Yup.string().url(ERRORS.invalidUrl).required(ERRORS.urlReq),
  image: Yup.mixed().required(ERRORS.logoReq),
});

export const schemaAddFields = Yup.object({
  fieldName: Yup.string()
    .min(3, ERRORS.fieldMin)
    .max(40, ERRORS.fieldMax)
    .required(ERRORS.fieldReq),
});

export const schemaForgotEmail = Yup.object().shape({
  email: Yup.string().email(ERRORS.invalidEmail).required(ERRORS.emailReq),
});

export const schemaUpdatePassword = Yup.object().shape({
  password: Yup.string()
    .required(ERRORS.passwordReq)
    .matches(REGEX.password, ERRORS.password),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], ERRORS.passMatch)
    .required(ERRORS.cngmPassReq)
    .matches(REGEX.password, ERRORS.passwordCnfm),
  otp: Yup.string().matches(REGEX.otp, ERRORS.otp).required(ERRORS.otpReq),
});
