import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
} from "@coreui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { BsCameraFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCookie } from "react-use-cookie";
import { LABELS } from "src/components/labels/appLabels";
import { ERRORS } from "src/components/labels/error";
import { SUCCESS } from "src/components/labels/success";
import { schemaForCompanyAndSchool } from "src/components/schema";
import {
  addSchool,
  getSchoolData as getSchoolsData,
  reset,
  updateSchool,
} from "src/features/dashboard/dashboardSlice";
import dummyProfile from "../../assets/images/avatars/dummy-profile.png";
import Loader from "../../components/loader";
import { ROUTES } from "src/components/routes/routes";
import { Helmet } from 'react-helmet';

const AddSchool = () => {
  const userData = getCookie("userData");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const hiddenProfileFileInput = useRef(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { id } = useParams();
  const userDataString = userData ? JSON.parse(userData) : null;
  const {
    schoolAdd,
    getSchoolData,
    data,
    updateSchool: schoolUpdate,
  } = useSelector((state) => state.dashboard);
  const [editTrue, setEditTrue] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    webUrl: "",
    image: null,
  });
  useEffect(() => {
    if (!id) return;
    dispatch(getSchoolsData(id));
  }, []);

  useEffect(() => {
    if (getSchoolData.isSuccess) {
      setEditTrue(true);
      if (data.getSchoolData && !data?.getSchoolData?.profileImage) {
        setSchoolId(data.getSchoolData._id);
        setInitialValues({
          name: data.getSchoolData.name,
          webUrl: data.getSchoolData.webSiteUrl,
          image: null,
        });
      } else if (data.getSchoolData?.profileImage) {
        setSchoolId(data.getSchoolData._id);
        setInitialValues({
                name: data.getSchoolData.name,
                webUrl: data.getSchoolData.webSiteUrl,
                image: data.getSchoolData?.profileImage,
              });
      }
    }
    return () => {
      dispatch(reset());
    };
  }, [getSchoolData.isSuccess, dispatch]);

  useEffect(() => {
    if (schoolAdd.isError) {
      toast.error(schoolAdd.message);
      dispatch(reset());
    }
  }, [schoolAdd.isError, schoolAdd.message, dispatch]);

  useEffect(() => {
    if (schoolAdd.isSuccess) {
      toast.success(SUCCESS.schoolCreate);
      dispatch(reset());
      navigation(ROUTES.dashboard.schools);
    }
  }, [schoolAdd.isSuccess, dispatch]);

  useEffect(() => {
    if (schoolUpdate.isError) {
      toast.error(schoolUpdate.message);
      dispatch(reset());
    }
  }, [schoolUpdate.isError, schoolUpdate.message, dispatch]);

  useEffect(() => {
    if (schoolUpdate.isSuccess) {
      toast.success(SUCCESS.schoolUpdated);
      dispatch(reset());
      navigation(ROUTES.dashboard.schools);
    }
  }, [schoolUpdate.isSuccess, dispatch]);

  const handleSubmit = (values) => {
    if (editTrue) {
      let payload = {
        schoolId: schoolId,
      };
      
      let isUpdated = false;
      console.log('isUpdated',isUpdated,payload)
      if (initialValues.name !== values.name) {
        payload.name = values.name.toLowerCase();
        isUpdated = true;
      }
      
      if (initialValues.webUrl !== values.webUrl) {
        payload.webSiteUrl = values.webUrl;
        isUpdated = true;
      }
      console.log('values.image',values.image,initialValues.image)
      if (
        initialValues.image &&
        initialValues.image.startsWith('https') &&
        initialValues.image  !== values.image ||  initialValues.image  !== values.image
      ) {
        payload.profileImage = values.image;
        isUpdated = true;
      }
      
      if (!isUpdated) {
        toast.error(ERRORS.notUpdateData);
        return; 
      }
      dispatch(updateSchool(payload));
    } else {
      const payload = {
        ...values,
        id: userDataString._id,
      };
      dispatch(addSchool(payload));
    }
  };

  const handleProfileClick = () => {
    if (hiddenProfileFileInput.current) {
      hiddenProfileFileInput.current.click();
    }
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file);
      setFieldValue("image", file);
    }
  };

  return (
    <>
    <Helmet>
        <title>{editTrue ? 'Edit School' : 'Add School'} | Flocks Admin</title>
      </Helmet>
      {getSchoolData.isLoading  ? (
        <div className="loader-alignment">
          <Loader />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schemaForCompanyAndSchool}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form className="row g-3 needs-validation">
              <CCard className="p-3">
                <CContainer>
                  <CRow className="pb-3">
                    <CCol xs="auto" className="me-auto">
                      <h3>
                        {editTrue
                          ? LABELS.heading.editSchool
                          : LABELS.heading.addSchool}
                      </h3>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center pb-3 ">
                    <CCol>
                      <Field name="image">
                        {({ field }) => (
                          <>
                            <CFormLabel htmlFor="inputGroupFile02">
                              {" "}
                              {LABELS.tableData.logo}
                            </CFormLabel>
                            <div className="img-div">
                              <input
                                type="file"
                                ref={hiddenProfileFileInput}
                                onChange={(e) =>
                                  handleFileChange(e, setFieldValue)
                                }
                                accept="image/png, image/jpeg"
                                hidden
                              />
                              <button
                                className="btn-img"
                                onClick={handleProfileClick}
                                type="button"
                              >
                                <BsCameraFill color="#000000" />
                              </button>
                            </div>
                            <img
                              src={
                                profilePhoto
                                  ? URL.createObjectURL(profilePhoto)
                                  : initialValues.image ? initialValues.image : dummyProfile
                              }
                              alt="User Profile"
                              width={100}
                              height={100}
                              className="img-company"
                            />
                            <ErrorMessage
                              name="image"
                              component="div"
                              className="text-danger"
                            />
                          </>
                        )}
                      </Field>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-start pb-3">
                    <CCol md={6}>
                      <Field name="name">
                        {({ field }) => (
                          <>
                            <CFormInput
                              {...field}
                              type="text"
                              label="Name"
                              placeholder="Xord"
                              aria-describedby="exampleFormControlInputHelpInline"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </>
                        )}
                      </Field>
                    </CCol>
                    <CCol md={6}>
                      <Field name="webUrl">
                        {({ field }) => (
                          <>
                            <CFormInput
                              {...field}
                              type="text"
                              label="Website URL"
                              placeholder="https://example.com"
                              aria-describedby="exampleFormControlInputHelpInline"
                            />
                            <ErrorMessage
                              name="webUrl"
                              component="div"
                              className="text-danger"
                            />
                          </>
                        )}
                      </Field>
                    </CCol>
                  </CRow>
                <CCol xs={12}>
                  <div className="d-flex justify-content-end align-items-end">
                    <CButton
                      color="primary"
                      type="submit"
                      disabled={schoolAdd.isLoading || schoolUpdate.isLoading}
                      className={
                        schoolAdd.isLoading || schoolUpdate.isLoading
                          ? "loadingBtn"
                          : ""
                      }
                    >
                      {schoolAdd.isLoading || schoolUpdate.isLoading
                        ? <CSpinner color="base" className="loader-class" />
                        : editTrue
                        ? LABELS.button.editSchool
                        : LABELS.button.addSchool}
                    </CButton>
                  </div>
                </CCol>
                </CContainer>
              </CCard>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddSchool;
