// PaginationComponent.js
import { CPagination, CPaginationItem } from "@coreui/react";
import React from "react";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  decrementPage,
  incrementPage,
}) => {
  const getPageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= 3) {
      return [1, 2, 3, 4, "...", totalPages];
    } else if (currentPage >= totalPages - 2) {
      return [
        1,
        "...",
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    }
  };

  const pageNumbers = getPageNumbers();

  return (
    <>
      <CPagination aria-label="Page navigation example" className="pagination">
        <CPaginationItem
          className={`${
            currentPage === 1 ? "btn-pagination-not" : "btn-pagination"
          }`}
          onClick={decrementPage}
          disabled={currentPage === 1}
        >
          Previous
        </CPaginationItem>
        {pageNumbers.map((page, index) =>
          page === "..." ? (
            <CPaginationItem key={index} disabled>
              ...
            </CPaginationItem>
          ) : (
            <CPaginationItem
              key={page}
              className={`page-number ${page === currentPage ? "active" : ""}`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </CPaginationItem>
          )
        )}
        <CPaginationItem
          onClick={incrementPage}
          className={`${
            currentPage === totalPages ? "btn-pagination-not" : "btn-pagination"
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </CPaginationItem>
      </CPagination>
    </>
  );
};

export default Pagination;
