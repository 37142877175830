import { ENDPOINT } from "src/components/endpoint/endpoint";
import axios from "src/utils/axiosInstance";

const getAllUsers = async (payload) => {
  let response;
  if (payload.isBanned === "all") {
    response = await axios.get(
      `${ENDPOINT.users.getAllUsers}?page=${payload.page}&row=10`
    );
  } else {
    response = await axios.get(
      `${ENDPOINT.users.getAllUsers}?page=${payload.page}&row=10&isBanned=${payload.isBanned}`
    );
  }

  if (response.data.data.length === 0 && payload.page > 1) {
    response = await axios.get(
      `${ENDPOINT.users.getAllUsers}?page=${payload.page - 1}&row=10&isBanned=${
        payload.isBanned
      }`
    );
  }
  return response;
};

const getAllCompanies = async (payload) => {
  const response = await axios.get(
    `${ENDPOINT.company.getAllCompanies}?page=${payload.page}&row=10`
  );
  return response;
};

const getCompanyData = async (id) => {
  const response = await axios.get(
    `${ENDPOINT.company.getAllCompanies}?companyId=${id}`
  );
  return response;
};

const getSchoolData = async (id) => {
  const response = await axios.get(
    `${ENDPOINT.school.getAllSchools}?schoolId=${id}`
  );
  return response;
};

const verifyCompanies = async (payload) => {
  const response = await axios.patch(ENDPOINT.company.verifyCompany, payload);
  return response;
};

const verifySchool = async (payload) => {
  const response = await axios.patch(ENDPOINT.school.verifySchool, payload);
  return response;
};

const deleteSchool = async (payload) => {
  const response = await axios.delete(ENDPOINT.school.deleteSchool, {
    data: payload,
  });
  return response;
};

const deleteCompany = async (payload) => {
  const response = await axios.delete(ENDPOINT.company.deleteCompany, {
    data: payload,
  });
  return response;
};

const getAllSchools = async (payload) => {
  const response = await axios.get(
    `${ENDPOINT.school.getAllSchools}?page=${payload.page}&row=10`
  );
  return response;
};

const bannedUser = async (payload) => {
  const response = await axios.patch(ENDPOINT.users.bannedUser, payload);
  return response;
};

const addCompany = async (payload) => {
  const companyPayload = {
    name: payload.name.toLowerCase(),
    webSiteUrl: payload.webUrl.toLowerCase(),
    userId: payload.id,
  };
  const response = await axios.post(
    ENDPOINT.company.addCompany,
    companyPayload
  );
  if (response) {
    const imgUrlPayload = {
      companyId: response.data.data._id,
    };
    const responseImgUrl = await axios.post(
      ENDPOINT.company.companyProfile,
      imgUrlPayload
    );
    if (responseImgUrl) {
      const headers = new Headers();
      headers.append("Content-Type", "image/png");
      const options = {
        method: "PUT",
        headers: headers,
        body: payload.image,
      };
      const responsePutUrl = await fetch(
        responseImgUrl.data.data.putUrl,
        options
      );

      if (responsePutUrl.status === 200) {
        const imgUploadUrl = {
          companyId: response.data.data._id,
          profileImage: responseImgUrl.data.data.getUrl,
        };
        const responseImgUpload = await axios.patch(
          ENDPOINT.company.updateCompany,
          imgUploadUrl
        );
        return responseImgUpload;
      }
    }
  }
};

const addSchool = async (payload) => {
  const schoolPayload = {
    name: payload.name.toLowerCase(),
    webSiteUrl: payload.webUrl.toLowerCase(),
    userId: payload.id,
  };
  const response = await axios.post(ENDPOINT.school.addSchool, schoolPayload);
  if (response) {
    const imgUrlPayload = {
      schoolId: response.data.data._id,
    };
    const responseImgUrl = await axios.post(
      ENDPOINT.school.schoolProfile,
      imgUrlPayload
    );
    if (responseImgUrl) {
      const headers = new Headers();
      headers.append("Content-Type", "image/png");
      const options = {
        method: "PUT",
        headers: headers,
        body: payload.image,
      };
      const responsePutUrl = await fetch(
        responseImgUrl.data.data.putUrl,
        options
      );
      if (responsePutUrl.status === 200) {
        const imgUploadUrl = {
          schoolId: response.data.data._id,
          profileImage: responseImgUrl.data.data.getUrl,
        };
        const responseImgUpload = await axios.patch(
          ENDPOINT.school.updateSchool,
          imgUploadUrl
        );
        return responseImgUpload;
      }
    }
  }
};

const updateSchool = async (payload) => {
  try {
    const profileImage = payload.profileImage;

    const uploadProfileImage = async (imgUrlPayload, profileImage) => {
      const responseImgUrl = await axios.post(
        ENDPOINT.school.schoolProfile,
        imgUrlPayload
      );

      if (responseImgUrl) {
        const headers = new Headers();
        headers.append("Content-Type", profileImage.type || "image/png");
        const options = {
          method: "PUT",
          headers: headers,
          body: profileImage,
        };

        const responsePutUrl = await fetch(
          responseImgUrl.data.data.putUrl,
          options
        );

        if (responsePutUrl.status === 200) {
          const imgUploadUrl = {
            schoolId: payload.schoolId,
            profileImage: responseImgUrl.data.data.getUrl,
          };

          const responseImgUpload = await axios.patch(
            ENDPOINT.school.updateSchool,
            imgUploadUrl
          );

          return responseImgUpload;
        }
      }
      throw new Error("Failed to upload profile image");
    };

    const isProfileImageUrl =
      typeof profileImage === "string" && profileImage.startsWith("https");

    if (payload.name || payload.webSiteUrl) {
      if (isProfileImageUrl) {
        const res = await axios.patch(ENDPOINT.school.updateSchool, payload);
        return res;
      } else {
        delete payload.profileImage;
        const res = await axios.patch(ENDPOINT.school.updateSchool, payload);

        if (profileImage) {
          const imgUrlPayload = {
            schoolId: payload.schoolId,
          };

          const responseImgUpload = await uploadProfileImage(
            imgUrlPayload,
            profileImage
          );
          return responseImgUpload;
        }

        return res;
      }
    } else {
      if (profileImage && !isProfileImageUrl) {
        delete payload.profileImage;
        const imgUrlPayload = {
          schoolId: payload.schoolId,
        };

        const responseImgUpload = await uploadProfileImage(
          imgUrlPayload,
          profileImage
        );
        return responseImgUpload;
      } else {
        const res = await axios.patch(ENDPOINT.school.updateSchool, payload);
        return res;
      }
    }
  } catch (error) {
    console.error("Error updating school:", error);
    throw error;
  }
};

const updateCompany = async (payload) => {
  try {
    const profileImage = payload.profileImage;

    const uploadProfileImage = async (imgUrlPayload, profileImage) => {
      const responseImgUrl = await axios.post(
        ENDPOINT.company.companyProfile,
        imgUrlPayload
      );

      if (responseImgUrl) {
        const headers = new Headers();
        headers.append("Content-Type", profileImage.type || "image/png");
        const options = {
          method: "PUT",
          headers: headers,
          body: profileImage,
        };

        const responsePutUrl = await fetch(
          responseImgUrl.data.data.putUrl,
          options
        );

        if (responsePutUrl.status === 200) {
          const imgUploadUrl = {
            companyId: payload.companyId,
            profileImage: responseImgUrl.data.data.getUrl,
          };

          const responseImgUpload = await axios.patch(
            ENDPOINT.company.updateCompany,
            imgUploadUrl
          );

          return responseImgUpload;
        }
      }
      throw new Error("Failed to upload profile image");
    };

    const isProfileImageUrl =
      typeof profileImage === "string" && profileImage.startsWith("https");

    if (payload.name || payload.webSiteUrl) {
      if (isProfileImageUrl) {
        const res = await axios.patch(ENDPOINT.company.updateCompany, payload);
        return res;
      } else {
        delete payload.profileImage;
        const res = await axios.patch(ENDPOINT.company.updateCompany, payload);

        if (profileImage) {
          const imgUrlPayload = {
            companyId: payload.companyId,
          };

          const responseImgUpload = await uploadProfileImage(
            imgUrlPayload,
            profileImage
          );
          return responseImgUpload;
        }

        return res;
      }
    } else {
      if (profileImage && !isProfileImageUrl) {
        delete payload.profileImage;
        const imgUrlPayload = {
          companyId: payload.companyId,
        };

        const responseImgUpload = await uploadProfileImage(
          imgUrlPayload,
          profileImage
        );
        return responseImgUpload;
      } else {
        const res = await axios.patch(ENDPOINT.company.updateCompany, payload);
        return res;
      }
    }
  } catch (error) {
    console.error("Error updating company:", error);
    throw error;
  }
};

const addField = async (payload) => {
  const response = await axios.post(ENDPOINT.field.addField, payload);
  return response;
};

const getAllFields = async (payload) => {
  const response = await axios.get(
    `${ENDPOINT.field.getAllFields}?page=${payload.page}&row=10`
  );
  return response;
};

const dashboardService = {
  getAllUsers,
  getAllCompanies,
  getAllSchools,
  deleteSchool,
  bannedUser,
  addCompany,
  addSchool,
  addField,
  verifySchool,
  verifyCompanies,
  deleteCompany,
  getAllFields,
  getCompanyData,
  getSchoolData,
  updateSchool,
  updateCompany,
};

export default dashboardService;
